/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import SEO from 'components/seo';
import CallSection from 'components/CallSection/CallSection';
import JobSection from 'components/JobSection/JobSection';
import MapSection from 'components/MapSection/MapSection';
import JobTableSection from 'components/JobTableSection/JobTableSection';

import { CallContext } from 'context/callContextContainer';
import { callcenterService } from 'services/callcenterService';

const IndexPage = () => {
  const [driverSectionLayout, setDriverSectionLayout] = React.useState(1);
  const [jobTableRefresh, setJobTableRefresh] = useState(false);
  const [reservationTableRefresh, setReservationTableRefresh] = useState(false);
  const [callBases, setCallBases] = useState([]);
  const { setBasesSettings } = useContext(CallContext);

  useEffect(() => {
    async function getCallCenterBasesList() {
      const data = await callcenterService.get_linked_bases();
      if (data && data.data.status === 'SUCCESS' && data.data.data.num_rows) {
        const bases = data.data.data.data;
        setCallBases(bases);
        const baseSettings = {};
        bases.forEach(base => {
          baseSettings[base.dbname] = base.site;
        });
        setBasesSettings(baseSettings);
      }
    }

    getCallCenterBasesList();
  }, []);

  return (
    <>
      <SEO title="Call Center Home" />

      <MainWrapper className="main-wrapper" id="main-wrapper">
        <div className="main-container">
          <div className="main--call-center-section">
            {callBases?.map((base, index) => (
              <CallSection isCallCenter callBase={base} key={index} />
            ))}
          </div>

          <div className="main--job-map-section">
            <JobSection mapType="MAPBOX" jobTableRefresh={() => setJobTableRefresh(!jobTableRefresh)} isCallCenter />

            <div
              className={`main--map-section-wrapper ${
                driverSectionLayout === 0 ? 'horizontal-layout' : 'vertical-layout'
              }`}
            >
              <MapSection mapType="MAPBOX" isCallCenter />
            </div>
          </div>

          <JobTableSection
            jobTableRefresh={jobTableRefresh}
            reservationTableRefresh={reservationTableRefresh}
            isCallCenter
          />
        </div>
      </MainWrapper>
    </>
  );
};

export default IndexPage;

const MainWrapper = styled.div`
  .main-container {
    padding: 10px;

    .main--call-center-section {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;

      & > div {
        width: calc(50% - 5px);

        @media (max-width: 1199px) {
          width: 100%;
        }
      }
    }

    .main--job-map-section {
      margin-top: 10px;
      display: flex;

      .main--map-section-wrapper {
        flex: auto;
        display: flex;

        .main--driver-section {
          flex: 1;
          max-width: 400px;
          border: 2px solid var(--blue-dark-color);
          height: 100%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          position: relative;

          .main--driver-title {
            background: var(--blue-dark-color);
            font-size: 16px;
            padding: 15px 10px;
          }

          .main--driver-body {
            height: 100%;
            position: relative;

            .MuiTableContainer-root {
              height: 100%;
              border-radius: 0;
              background: #0b1a2111;
            }

            .MuiTable-root {
              .MuiTableHead-root {
                background: var(--blue-dark-color);
              }

              .MuiTableCell-root {
                padding: 5px 10px;
                font-size: 13px;
                border-bottom: 1px solid #aaa;
              }

              .MuiTableCell-head {
                font-size: 14px;
                line-height: 25px;
                padding: 11px 5px;
                color: white;
              }
            }
          }

          .main--driver-layout-button {
            position: absolute;
            right: 5px;
            bottom: 5px;
            opacity: 0.5;
            text-align: right;

            .MuiIconButton-root {
              background: #ddd;

              &:hover {
                background: #ddf;
              }
            }

            &:hover {
              opacity: 1;
            }
          }
        }

        &.vertical-layout {
          flex-direction: column;

          .main--map-section {
            margin-right: 0;
          }

          .main--driver-section-wrapper {
            padding-top: 10px;
          }

          .main--driver-section {
            max-width: 100%;
            max-height: 160px;
            overflow: auto;
          }
        }
      }
    }
  }

  @media (max-width: 1299px) {
    .main-container {
      .main--job-map-section {
        .main--map-section-wrapper {
          flex-direction: column;

          .main--driver-section {
            .main--driver-layout-button {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: 830px) {
    .main-container {
      .main--job-map-section {
        flex-direction: column;
      }
    }
  }
`;
